/*
REGISTER LOGIC:
1. user fills in the form
2. user clicks "verify email"
3. system checks if all fields all valid
  3.1 all valid, next page
  3.2 not valid, present error message
4. system shows next page with button to send verification code
5. users clicks on "send verification code"
6. system sends a code to user's email, and saved the email and code in db, set emailVerified as false
6. users input code
7. system checks if code is valid
  7.1 code is valid, "register" button is clickable
  7.2 code is invalid, present error message
8. user can go back to change form input, email can be changed
9. user clicks "register" button
10. system creates a user with shopify first
11. system creates a user with db, and set emailVerified as true, and clear the code
12. system signs in the user
  12.1 TODO: if not successful, set error message, if shopify registered successfully, but not with db, delete user from shopify
*/

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../../components/Form/FormInput/FormInput";
import {
  registerUserAPI,
  sendEmailCodeAPI,
  updateUserAPI,
} from "../../api/auth";
import { CREATE_USER_MUTATION } from "../../queries/userQueries";
import { useMutation } from "@apollo/client";
import { encodeEmail } from "../../util/userRelated";
import { emailAddress } from "../../data/generalData";
import { GlobalContext } from "../../context/globalContext";
import Spinner from "../../components/Spinner/Spinner";

const termsPDF = require("../../assets/pdf/Events House Terms & Conditions.pdf");
const privacyPDF = require("../../assets/pdf/Events House Privacy Policy.pdf");
const emailIcon = require("../../assets/media/icon/email1.png");
const lockIcon = require("../../assets/media/icon/lock1.png");
const titleIcon = require("../../assets/media/icon/name7.png");
const nameIcon = require("../../assets/media/icon/name6.png");
const salesIcon = require("../../assets/media/icon/people.png");

export function Register() {
  let navigate = useNavigate();
  const { setCurrentUser } = useContext(GlobalContext);

  // INPUTS //
  const [formInfo, setFormInfo] = useState({
    email: "",
    password: "",
    lastName: "",
    firstName: "",
    title: "",
    organisation: "",
    position: "",
    country: "",
    passwordRepeat: "",
    checkboxTermsPrivacy: false,
  });
  const [verificationCode, setVerificationCode] = useState("");

  // STATES //
  const [isNextPageClicked, setIsNextPageClicked] = useState(false);
  const [codeIsSent, setCodeIsSent] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  // GRAPHQL MUTATIONS //
  const [registerUserWithShopify] = useMutation(CREATE_USER_MUTATION);

  // MESSAGES //
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  // TESTED //
  const sendEmailVerification = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setIsSendingCode(true);
    try {
      const response = await sendEmailCodeAPI({
        email: formInfo.email,
      });

      if (response && response.status === 200) {
        setCodeIsSent(true);
      } else {
        setErrorMessage("Send verification failed. Please try again.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendingCode(false);
    }
  };

  // WILL ONLY RUN THIS METHOD WHEN EMAIL IS VERIFIED //
  // NOTE: Why not register with admin api in server code? because using admin api needs to send user invite to sent password //
  const registerWithEmail = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsSigningUp(true);

    const {
      email,
      password,
      lastName,
      firstName,
      title,
      organisation,
      position,
      country,
    } = formInfo;
    // TRY TO INSERT USER IN DB FIRST //
    let userId;
    try {
      const response = await registerUserAPI({
        email: email.trim(),
        password: password,
        title: title.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        organisation: organisation.trim(),
        position: position.trim(),
        country: country.trim(),
        code: verificationCode,
      });
      if (response && response.status === 200) {
        const data = await response.json();
        userId = data.user.id;
      } else {
        setErrorMessage("User creation failed. Please try again later.");
        setIsSigningUp(false);
        return;
      }
    } catch (error) {
      setIsSigningUp(false);
      console.error("User creation error:", error);
      setErrorMessage(
        "An error occurred during user creation. Please try again later."
      );
      return;
    }

    // TRY TO REGISTER WITH SHOPIFY (3 TIMES) //
    try {
      const shopifyCustomerId = await _tryRegisterWithShopify({
        email,
        firstName,
        lastName,
      });

      const response = await updateUserAPI({
        userId,
        email,
        shopifyCustomerId,
      });
      if (!response) {
        setErrorMessage("Failed to update user with Shopify customer ID. Please try again later.");
        return;
      }
      if (response && response.status !== 200) {
        setErrorMessage("User registration failed. Please try again later.");
        setIsSigningUp(false);
        return;
      }

      setMessage("Registration successful! Redirecting you to home page.");
      resetForm();
      const data = await response.json();
      const { id } =
        data.user;
      setCurrentUser({
        id,
        shopifyCustomerId,
        email,
        firstName,
        lastName,
        title,
      });
      setIsSigningUp(false);
      navigate("/");




    } catch (err: any) {
      let errorCode = err.message;
      errorCode = ["408", "409", "410"].includes(errorCode) ? errorCode : "411";

      const errorMessage =
        errorCode === "409"
          ? `We are sorry, customer limit exceeded, please kindly refresh and try again after a few minutes. If the problem persists, please contact our support team ${emailAddress.tech} for assistance with the following error code: ERROR CODE ${errorCode} Login`
          : `We are sorry, we're unable to complete your request at the moment, please contact our support team ${emailAddress.tech} for assistance with the following error code: ERROR CODE ${errorCode} Login`;

      setErrorMessage(errorMessage);
      setIsSigningUp(false);
      return;
    }




    // TRY REGISTER WITH SHOPIFY AND GET customerId, 3 TIMES, EACH TIME WAIT FOR 2s //
    async function _tryRegisterWithShopify(user: any) {
      let retry = true;
      let errorResponse = null;
      let count = 0;

      while (retry && count < 3) {
        try {
          const customerId = await _registerWithShopify(user);
          retry = false;
          errorResponse = null;
          return customerId;
        } catch (error: any) {
          console.error(error);
          if (error.code === 'TAKEN' && error.field.includes('email')) {
            errorResponse = "408";
            retry = false;
          } else if (error.message === "Creating Customer Limit exceeded. Please try again later.") {
            errorResponse = "409";
            retry = false;
          } else {
            errorResponse = "410";
            retry = true;
            await new Promise((resolve) => setTimeout(resolve, 2000)); // wait for 2 seconds
          }
          count++;
        }
      }

      if (retry || errorResponse) {
        throw new Error(errorResponse || "An error occurred");
      }
    }

    // USE SHOPIFY STOREFRONT API CREATE USER MUTATION AND RETURN customerId //
    async function _registerWithShopify(user: any) {
      const { email, firstName, lastName } = user;

      return new Promise((resolve, reject) => {
        registerUserWithShopify({
          variables: {
            input: {
              email: email,
              firstName: firstName,
              lastName: lastName,
              password: encodeEmail(email),
            },
          },
          onCompleted: ({ customerCreate }) => {
            if (customerCreate.customerUserErrors && customerCreate.customerUserErrors.length > 0) {
              reject(customerCreate.customerUserErrors[0]);
            } else if (customerCreate.customer) {
              const customerId = customerCreate.customer.id.split("/").pop();
              resolve(customerId);
            } else {
              reject(new Error("Failed to create customer"));
            }
          },
          onError: (error) => {
            console.error(error);
            reject(error);
          },
        });
      });
    }


  };

  function resetForm() {
    setFormInfo({
      email: "",
      password: "",
      lastName: "",
      firstName: "",
      title: "",
      organisation: "",
      position: "",
      country: "",
      passwordRepeat: "",
      checkboxTermsPrivacy: false,
    });
  };

  const handleNextPage = () => {
    setMessage("");
    setErrorMessage("");
    const {
      email,
      password,
      lastName,
      firstName,
      title,
      organisation,
      position,
      country,
      passwordRepeat,
      checkboxTermsPrivacy,
    } = formInfo;

    if (
      !email ||
      !password ||
      !title ||
      !firstName ||
      !lastName ||
      !organisation ||
      !position ||
      !passwordRepeat
    ) {
      setErrorMessage("Missing fields.");
      return;
    }
    if (!country) {
      setErrorMessage("Please provide your country.");
      return;
    }
    if (!email.includes("@")) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    if (password !== passwordRepeat) {
      setErrorMessage("Passwords do not match.");
      return;
    }
    if (!checkboxTermsPrivacy) {
      setErrorMessage(
        "Please agree with the terms and conditions and the privacy policy."
      );
      return;
    }

    setIsNextPageClicked(true);
  };
  return (
    <div className="register-container mt-16">
      {!isNextPageClicked ? (
        <form
          className="form-register "
          id="form-register"
          onChange={() => {
            setMessage("");
            setErrorMessage("");
          }}
        >
          <div className="flex gap-2">
            <FormInput
              input={{
                class: "static",
                label: "Title *",
                type: "txt",
                value: formInfo.title,
                img: titleIcon,
                update: (value: string) => {
                  setFormInfo({ ...formInfo, title: value });
                },
              }}
            />
            <FormInput
              input={{
                class: "static",
                label: "First Name *",
                type: "txt",
                value: formInfo.firstName,
                img: nameIcon,
                update: (value: string) => {
                  setFormInfo({ ...formInfo, firstName: value });
                },
              }}
            />
            <FormInput
              input={{
                class: "static",
                label: "Last Name *",
                type: "txt",
                value: formInfo.lastName,
                img: nameIcon,
                update: (value: string) => {
                  setFormInfo({ ...formInfo, lastName: value });
                },
              }}
            />
          </div>
          <FormInput
            input={{
              class: "static",
              label: "Email *",
              type: "txt",
              value: formInfo.email,
              img: emailIcon,
              update: (value: string) => {
                setFormInfo({ ...formInfo, email: value });
              },
            }}
          />
          <FormInput
            input={{
              class: "static",
              label: "Password *",
              type: "password",
              value: formInfo.password,
              img: lockIcon,
              update: (value: string) => {
                setFormInfo({ ...formInfo, password: value });
              },
            }}
          />
          <FormInput
            input={{
              class: "static",
              label: "Repeat Password *",
              type: "password",
              value: formInfo.passwordRepeat,
              img: lockIcon,
              update: (value: string) => {
                setFormInfo({ ...formInfo, passwordRepeat: value });
              },
            }}
          />
          <div className="flex gap-2">
            <FormInput
              input={{
                class: "static",
                label: "Organisation *",
                type: "txt",
                value: formInfo.organisation,
                img: emailIcon,
                update: (value: string) => {
                  setFormInfo({ ...formInfo, organisation: value });
                },
              }}
            />
            <FormInput
              input={{
                class: "static",
                label: "Position *",
                type: "txt",
                value: formInfo.position,
                img: salesIcon,
                update: (value: string) => {
                  setFormInfo({ ...formInfo, position: value });
                },
              }}
            />
            <FormInput
              input={{
                class: "static",
                label: "Country *",
                type: "txt",
                value: formInfo.country,
                img: titleIcon,
                update: (value: string) => {
                  setFormInfo({ ...formInfo, country: value });
                },
              }}
            />
          </div>
          <div className="register-table-signup">
            <label
              className={
                formInfo.checkboxTermsPrivacy
                  ? "checkbox-terms-privacy checked"
                  : "checkbox-terms-privacy"
              }
            >
              <input
                type="checkbox"
                checked={formInfo.checkboxTermsPrivacy}
                onChange={() => {
                  setFormInfo({
                    ...formInfo,
                    checkboxTermsPrivacy: !formInfo.checkboxTermsPrivacy,
                  });
                }}
              />
              &nbsp;*I agree with the{" "}
              <a className="underline underline-offset-2" href={termsPDF} target="_blank">
                terms and conditions
              </a>{" "}
              and the{" "}
              <a className="underline underline-offset-2" href={privacyPDF} target="_blank">
                privacy policy
              </a>
            </label>
            <div className="flex flex-col gap-2 items-center mb-40 mt-2">
              <button
                id="register-button"
                type="button"
                onClick={handleNextPage}
                className="mt-6 w-full btn btn-sm rounded-md bg-slate-800 text-white"
              >
                Verify Email
              </button>
              <button
                className=" btn btn-sm border-none bg-transparent rounded-md shadow-none"
                type="button"
                onClick={resetForm}
              >
                Reset Form
              </button>
              {errorMessage && <p className="text-red-900">{errorMessage}</p>}
            </div>
          </div>
        </form>
      ) : (
        <>
          <form
            className="mt-10"
            onChange={() => {
              setErrorMessage("");
            }}
          >
            <p>Email: {formInfo.email}</p>
            <div className="flex gap-2 items-end">
              <FormInput
                input={{
                  class: "static",
                  label: "Verification Code",
                  type: "txt",
                  value: verificationCode,
                  img: lockIcon,
                  update: (value: string) => {
                    setVerificationCode(value);
                  },
                }}
              />
              <button
                type="button"
                className="btn btn-sm text-nowrap rounded-md"
                onClick={(e) => {
                  setErrorMessage("");
                  setMessage("");
                  sendEmailVerification(e);
                }}
              >
                {isSendingCode ? <Spinner /> : "Send Verification Code"}
              </button>
            </div>
          </form>
          <p className="text-sm">Please use a VPN if registration is not successful. <br />Else please contact {emailAddress.tech}</p>

          <div className="flex flex-col gap-3 items-center mt-6">
            {codeIsSent && <p>Sent! Please check your email.</p>}
            <button
              className="btn btn-sm w-full bg-slate-800 text-white"
              type="button"
              onClick={(e) => {
                registerWithEmail(e);
                setErrorMessage("");
                setMessage("");
              }}
              disabled={!verificationCode}
            >
              {isSigningUp ? <Spinner /> : "Create My Account Now"}
            </button>
            <button
              type="button"
              onClick={() => {
                setIsNextPageClicked(false);
                setErrorMessage("");
                setMessage("");
                setCodeIsSent(false);
              }}
            >
              Back
            </button>
            {errorMessage && <p className="text-red-700">{errorMessage}</p>}
            {message && (
              <p>
                <b>{message}</b>
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

