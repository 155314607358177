export const API_ENDPOINT = process.env.NODE_ENV === "production"
  ? "https://eventshouse.net/api/events/v1"
  : "https://pixelatedbytes.io:443/api/events/v1";





export const FUSION_CODE_API_ENDPOINT =
  "https://bocas-merchant.fusionpay.co.uk/api/v2;";


// "https://api.siqisu.co.uk/api/events/v1";
// "http://127.0.0.1:4000/api/events/v1";