// import { getUserInfoInFirebase } from "../data/firebase/firebaseDB";
// import { apolloClient } from "../index";
// import { CREATE_ACCESS_TOKEN_MUTATION } from "../queries/userQueries";
// import { updateUserInfoInFirestore } from '../data/firebase/firebaseDB';
// import { GET_ORDERS_QUERY } from "../queries/orderQueries";

// export const verifyUserHasConferenceMembership = async (currentUser) => {
//     try {
//         const accessToken = await getUserInfoInFirebase(currentUser.uid, "accessToken");
//         // console.log(accessToken);
//         if (accessToken) {
//             const result = await apolloClient.mutate({
//                 // const { customer } = await apolloClient.mutate({
//                 // const { customer: { orders: { edges } }  } = await apolloClient.mutate({
//                 mutation: GET_ORDERS_QUERY,
//                 variables: {
//                     "input": {
//                         "customerAccessToken": `${accessToken}`
//                     }
//                 }
//             });
//         } else {
//             return null;
//         }
//     } catch (error) {
//         console.error(error);
//     }
// };

// export const createAccessTokenShopify = async (email, currentUser, test) => {
//     try {
//         // console.log(encodeEmail(email));
//         const { data: { customerAccessTokenCreate } } = await apolloClient.mutate({
//             mutation: CREATE_ACCESS_TOKEN_MUTATION,
//             variables: {
//                 "input": {
//                     "email": email,
//                     "password": encodeEmail(email)
//                 }
//             },
//         });
//         if (test) {
//             return {
//                 accessToken: customerAccessTokenCreate.customerAccessToken.accessToken,
//                 accessTokenExpires: customerAccessTokenCreate.customerAccessToken.expiresAt
//             };
//         } else {
//             if (customerAccessTokenCreate.customerUserErrors.length > 0 || !customerAccessTokenCreate.customerAccessToken) {
//                 console.error(customerAccessTokenCreate.customerUserErrors);
//                 return false;
//             } else {

//                 if (currentUser) {
//                     // console.log(currentUser);
//                     await updateUserInfoInFirestore(currentUser.uid, {
//                         accessToken: customerAccessTokenCreate.customerAccessToken.accessToken,
//                         accessTokenExpires: customerAccessTokenCreate.customerAccessToken.expiresAt
//                     });
//                     return true;
//                 } else {
//                     return false;
//                 }
//             }
//         }
//     } catch (err) {
//         console.error(err);
//         return false;
//     }
// };

// export const createOrRenewShopifyCustomerAccessToken = async (user, email) => {
//     const userInfo = await getUserInfoInFirebase(user.uid);
//     const now = new Date();
//     let needRenew = false;
//     if (userInfo) {
//         if (userInfo["accessTokenExpires"]) {
//             if (new Date(userInfo["accessTokenExpires"]).getTime() - now.getTime() < 17280000) { // TWO DAYS
//                 needRenew = true;
//             } else {
//                 needRenew = false;
//                 return true;
//             }
//         }
//         if (needRenew || !userInfo["accessToken"] || !userInfo["accessTokenExpires"]) {
//             const status = await createAccessTokenShopify(email, user);
//             // console.log(status);
//             return status;
//         }
//         return false;
//     } else {
//         return false;
//     }
// };

export function encodeEmail(email) {
  let asciiString = "";
  let finalPass = "";
  for (let i = 1; i < email.length; i += 3) {
    const asciiCode = email.charCodeAt(i);
    asciiString += `${asciiCode}`;
    // asciiString += String.fromCharCode(asciiCode);
  }
  let hexString = parseInt(asciiString).toString(16);
  while (hexString.length < 12) {
    hexString += hexString;
  }
  finalPass = hexString.substring(0, 12);
  return finalPass;
}
